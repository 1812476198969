//Этот файл - первичная точка входа
import "index.scss";

// import * as ServiceWorker from "serviceWorker";

(async() => {
  //Динамический импорт модуля Main. Webpack, автоматически разобьет бандл, на отдельные чанки, при таком подходе
  const { default: Main } = await import("lib/Main");
  //Вызываем статический Main.init(), дальнейшие действия, начинаются с него
  Main.init(document.getElementById("root"));
})();
